import WE from "../../../utils/wEditor";
import CountDown from "@/components/student/CountDown";
import {mapActions,mapGetters} from "vuex";
import _ from "underscore";
import CryptoJs from "crypto-js";
export default {
    name: "Index",
    components: {
        "count-down": CountDown,
    },
    data() {
        return {
            userName: localStorage.getItem('username') || '游客',
            stuAvatar: localStorage.getItem('headerImg') || require("../../../assets/kefu/stu-logo.png"),
            robotAvatar: require("../../../assets/kefu/robot-logo.png"),
            robots: [],
            tmpRobots: [],
            intervalTime: 0,
            goods: [],
            chatCurrentId: 0,
            status: false, //比赛状态
            kefuTitle: '',
            competitionType: Number(this.$lockr.get('competition_type')) || 1,
            kefuDescription: '客服模块提供店铺6款商品，根据商品信息在规定的时间内进行客服问答。系统对每个问答进行响应时间统计,点击开始计时，可事先准备再进行比赛',
            currentRobot: {
                robot_name: '',
                robot_data: [],
                response_time: 0
            },
            doneRobot: [],
            robotShowInstance: null,
            ringsList: [],
            isExam: 0,
            theRobotIndex: [], //记录每个机器人聊到那句问题的下标
            theEditor: null,
            tabIndex: 0, //左侧栏的切换状态，
            historyRobots: [],
            robot_current_id: null,
            robot_history_id: null,
            currentDoneNum: 0,
            donePreviewStatus: true,
            goodsTitle: '商品',
            totalRobotNum: 0,
            robotLen: 0,
            localTime: 0,
        }
    },
    computed: {
        ...mapGetters(['nowTimeStamp']),
        talkingRobotNum() {
            if (this.tabIndex === 0) {
				return `完成进度：<b>${this.currentDoneNum} / ${this.totalRobotNum}</b>`;
            } else {
                return this.historyRobots.length;
            }

        },
        tabTitle() {
            return this.tabIndex === 0 ?  '正在咨询' : '历史记录';
        },
        isContinue() {
            if (this.isExam === 0 && this.totalRobotNum > this.historyRobots.length && this.historyRobots.length > 0) {
                return true;
            } else {
                return false;
            }
        },

    },
    created() {
        this.doneList();
        this.init();
    },
    mounted() {
        let menus = [
            'bold',  // 粗体
            'fontSize',
            'italic',
            'fontName',
            'emoticon',
            'image',
            'undo'
        ];
        this.theEditor = new WE(document.getElementById('send-content'), this.$api.uploadImages,'image[]',menus, false, true);
        //监听在富文本框中输入shift+enter
        document.getElementById('send-content').addEventListener('keydown', (evt) => {
            if (evt.shiftKey === false && evt.keyCode === 13 && navigator.userAgent.toLowerCase().indexOf('windows nt') !== -1){
                evt.preventDefault();
                this.send();
            }
            if (evt.metaKey === false && evt.keyCode === 13 && navigator.userAgent.toLowerCase().indexOf('mac os x') !== -1) {
                evt.preventDefault();
                this.send();
            }
        });

    },
    beforeDestroy() {
        clearInterval(this.robotShowInstance);
    },
    methods: {
        ...mapActions(['setNowTimeStamp']),
        toGoodsDetail(id) {
            let route = "/student/kefugoods";
            const idstr = this.$common.encrypte(id);
            let routeUrl = this.$router.resolve({
                path: route,
                query: {
                    r: idstr
                }
            });
            window.open(routeUrl.href, "_blank");
        },
        sortBy() {
            if (this.tabIndex === 0 && this.robots.length) {
                this.robots = _.sortBy(this.robots, 'id');
            }
            if (this.tabIndex === 1 && this.historyRobots.length) {
                this.historyRobots = _.sortBy(this.historyRobots, 'id');
            }

        },
        scrollEvt() {
            let scrollEle = this.$refs.kefuContent.wrap;
            let childHeight = scrollEle.firstElementChild.clientHeight + 75;
            let parentHeight = scrollEle.offsetHeight;

            if (childHeight >= parentHeight) {
                let offsetH = childHeight - parentHeight;
                this.$nextTick(function() {
                    scrollEle.scrollTop = offsetH;
                })
            }
        },
        tabTo(val) {
            this.tabIndex = val;
            if (this.tabIndex === 1 && this.historyRobots.length >= 1) {
                this.donePreviewStatus = false;
                if (!this.robot_history_id) {
                    this.robot_history_id = this.historyRobots[0].robot_id;
                }
                this.switchTo(this.robot_history_id);
            } else if (this.tabIndex === 0 && this.robot_current_id) {
                    this.switchTo(this.robot_current_id);

            } else {
                this.currentRobot =  {
                    robot_name: '',
                    robot_data: [],
                    response_time: 0
                };
            }
        },
        setRobotId(robotId) {
            this.ringsList = _.filter(this.ringsList, (item) => {
                    return item !== robotId;
                });
        },
        send() {
            if (!this.status) {
                this.$message({
                    type: 'error',
                    message: '比赛已结束',
                    duration: 1000
                });
                return false;
            }
            if (this.totalRobotNum === this.currentDoneNum) {
                this.$message.success('已完成所有答题！');
                return;
            }
            if (this.isExam !== 1) {
                this.$message({
                    type: 'warning',
                    message: '请点击右侧【开始】按钮，开始答题~',
                    duration: 2000
                });
                return false;
            }
            this.setRobotId(this.chatCurrentId);
            let index = this.theRobotIndex[this.chatCurrentId];
            let content = this.theEditor.getWEHtml();
            content = content.replace(/<p><br><\/p>/g,'');
            content = content.replace(/<p>(&nbsp;\s+|&nbsp;)+<\/p>/g, '');
            let txtContentLen = this.theEditor.getWEText().length;
            if (txtContentLen > 100) {
                this.$message({
                    type: 'warning',
                    message: '消息字数超过100（包含符号与字母），无法发出，请重新编辑！',
                    duration: 1500
                });
                return;
            }
            if (content.length === 0) {
                this.$message({
                    type: "warning",
                    message: '不能发送空白消息',
                    duration: 1000
                });
                return;
            }
            //已回答完该机器人的答案
            if (index >= this.currentRobot.robot_data.length) {
                this.$message({
                    type: 'warning',
                    message: `${this.currentRobot.robot_name}：已回答完毕，请回答其他机器人的问题`,
                    duration: 1500
                });
                return false;
            }

            //在下个问题出来之前不可发送消息
            if (index >0 && this.currentRobot.robot_data[index-1].stu_key_word && this.currentRobot.robot_data[index].is_show === false) {
                this.$message({
                    type: 'warning',
                    message: `请等待${this.currentRobot.robot_name}提问下一个问题`,
                    duration: 3000
                });
                return false;
            }

            this.$set(this.currentRobot.robot_data[index], 'stu_key_word', content);
            this.$set(this.currentRobot.robot_data[index], 'answer_time', Date.parse(new Date()));
            this.$set(this.currentRobot.robot_data[index], 'stu_answer_time', this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
            this.averageTime(this.currentRobot);
            this.scrollEvt();
            this.theEditor.getNull();
            if (index + 1 === this.currentRobot.robot_data.length) {
                this.currentRobot.is_complete = true;
                this.saveRobotAnswer(this.currentRobot);
                this.theRobotIndex[this.chatCurrentId] = index + 1;
                return false;
            }
            this.setTimeShow(this.chatCurrentId, index);
            this.theRobotIndex[this.chatCurrentId] = index + 1;
        },
        averageTime(robot) {
            let time = 0;
            let num = 0;
            _.each(robot.robot_data, (item) => {
                if (item.answer_time && item.ask_time) {
                    time += item.answer_time - item.ask_time;
                    num +=1;
                }
            });
            let response_time = time/(num * 1000);
            robot.response_time = response_time.toFixed(2);
        },
        saveRobotAnswer(robot) {
            let data = {};
            data.robot_id = robot.id;
            data.stu_post_content = _.map(robot.robot_data, (item) => {
                let tmp = {};
                tmp.qusetion = item.question;
                tmp.stu_key_word = item.stu_key_word;
                tmp.stu_post_time = ((item.answer_time - item.ask_time)/1000).toFixed(2);
                tmp.robot_ask_time = this.$common.formatTimeStr(item.ask_time);
                tmp.stu_answer_time = this.$common.formatTimeStr(item.answer_time);
                return tmp;
            });
            let param = JSON.stringify(data);
            this.$http.onlyPost(this.$api.kefuSave, { stu_content: param }, (res) => {
                if (res.code === 200) {
                    this.currentDoneNum++;
                    this.$message({
                        type: 'success',
                        message: `${robot.robot_name}的问答已回答完毕`,
                        duration: 2000
                    });
                } else {
                    this.$message.warning(res.msg);
                }
            }, (error) => {
                this.$common.axiosErrorMsg(error);
            });

        },
        setTimeShow(robotId, robotSayIndex) {
            let _this = this;
            let robot = _.find(this.robots, {id: robotId});
            let time = Number(robot.robot_data[robotSayIndex+1].question_time)*1000;
            setTimeout(() => {
                robot.robot_data[robotSayIndex+1].is_show = true;
                robot.robot_data[robotSayIndex+1].ask_time = Date.parse(new Date());
                robot.robot_data[robotSayIndex+1].robot_ask_time = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                _this.scrollEvt();
                if (robot.id !== _this.chatCurrentId) {
                    _this.ringsList.push(robot.id);
                    _this.robots = _.filter(_this.robots, (item) => {
                        return item.id !== robot.id;
                    });
                    _this.robots.unshift(robot);
                } else {
                    _this.ringsList.push(robot.id);
                }

            }, time);
        },
        switchTo(id) {
            if (this.tabIndex === 0) {
                this.robot_current_id = id;
                let robot = _.find(this.robots, {id});
                this.chatCurrentId = id;
                /*this.ringsList = _.filter(this.ringsList, (item) => {
                    return item !== id;
                });*/
                if (!this.theRobotIndex[robot.id]) {
                    this.theRobotIndex[robot.id] = 0;
                    robot.robot_data[this.theRobotIndex[robot.id]].is_show = true;
                    robot.robot_data[this.theRobotIndex[robot.id]].ask_time = Date.parse(new Date());
                    robot.robot_data[this.theRobotIndex[robot.id]].robot_ask_time = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }
                this.currentRobot = robot;
            } else {
                this.robot_history_id = id;
                let doneRobot = _.find(this.historyRobots, {id});
                this.chatCurrentId = id;
                this.currentRobot = doneRobot;
            }

        },
        checkRing(id) {
            if (this.ringsList.indexOf(id) !== -1) {
                return 'active';
            } else {
                return '';
            }
        },
        toIndex() {
            if (this.currentDoneNum < this.totalRobotNum) {
                this.$confirm('当前客服机器人还未完成答题，确定退出?退出后记得再次进入完成所有客服机器人的答题').then(() => {
                    this.$router.push('/student/index');
                }).catch(() => {

                });
            } else {
                this.$router.push('/student/index');
            }
        },
        init() {
            this.$http.axiosGet(this.$api.kefuRobot, (res) => {
                if (res.code === 200) {
                    this.kefuDescription = res.data.explain;
                    let current_time = Number(res.data.time);
                    let begin_time = Number(this.$lockr.get('begin_time'));
                    let end_time = Number(this.$lockr.get('end_time'));
                    let left_time = end_time - current_time;
                    let before_begin_time = begin_time - current_time;
                    if (before_begin_time > 0) {
                        this.status = true;
                        this.isExam = -1;
                    }
                    if (left_time < 0) {
                        this.status = false;
                    }
                    if ((left_time > 0) && (before_begin_time <= 0)) {
                        this.status = true;
                    }

                    this.goods = _.map(res.data.goods_data, (item) => {
                        let tmp = {
                            goods_id: item.goods_id,
                            goods_image: item.goods_image ? item.goods_image.src : (item.goods_images.length ? item.goods_images[0].src : require("../../../assets/images/noimg.png")),
                            goods_name: item.goods_name,
                            goods_price: item.goods_price
                        };
                        return tmp;
                    });
                    if (res.data.goods_data.length === 0) {
                        this.goodsTitle = '暂无商品';
                    }
                    this.tmpRobots = _.map(res.data.robot_data, (item) => {
                        item.robot_data = _.map(item.robot_data, (robot_talk) => {
                            robot_talk.is_show = false;
                            robot_talk.stu_key_word = false;
                            robot_talk.ask_time = false;
                            robot_talk.answer_time = false;
                            robot_talk.stu_answer_time = false;
                            robot_talk.robot_ask_time = false;
                            return robot_talk;
                        });
                        item.response_time = 0;
                        return item;
                    });
					this.totalRobotNum += this.tmpRobots.length;
					this.robotLen = this.tmpRobots.length;
                    this.intervalTime = res.data.pv_interval;
                } else {
                    this.$message.warning(res.msg);
                }

            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        doneList() {
            this.$http.axiosGet(this.$api.kefuDone,(res) => {
                if (res.code === 200) {
                    let data_tmp = this.$common.decryptCode(res.data.code, res.data.isstramp);
                    let data = JSON.parse(data_tmp);
                    let done_robot = data.com_data;
                    this.historyRobots = _.map(done_robot, (item) => {
                        item.id = item.robot_id,
                        item.response_time = item.stu_avg_response_time;
                        item.first_answer = item.content[0].stu_key_word.replace(/<img src="[^]*\/uploads\/kefu\/images\/[^]*">/g, '[图片]'); //将图片替换成文字图片
                        item.robot_data = item.content;
                        return item;
                    });
					this.currentDoneNum = this.historyRobots.length;
					this.totalRobotNum += this.historyRobots.length;
                } else {
                    this.doneRobot = [];
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            });
        },
        startKefuModule() {
            if (this.tabIndex === 1) {
                this.$message({
                    type: 'warning',
                    message: '请切换到咨询界面开始答题',
                    duration: 1000
                });
                return;
            }
            if (!this.status) {
                return false;
            }
            if (this.tmpRobots.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '暂无可答题的机器人或已完成所有机器人的答题~',
                    duration: 1500
                });
                return false;
            }
            this.theEditor.getNull();
            this.isExam = 1;
            this.robotQueue();
            this.currentRobot = this.pushRobots(false);
            this.switchTo(this.currentRobot.id);
            this.ringsList.push(this.currentRobot.id);
            this.robot_current_id = this.currentRobot.id;
        },
        pushRobots(val=true) {
            let robotItem = this.tmpRobots.shift();
            if (val) {
                this.ringsList.push(robotItem.id);
            }
            this.robots.unshift(robotItem);
            return robotItem;
        },
        robotQueue() {
            let interval_time = this.intervalTime ? this.intervalTime : 1;
            let _this = this;
            this.robotShowInstance = setInterval(function () {
                if (_this.tmpRobots.length > 0) {
                    _this.pushRobots();
                    if (_this.tmpRobots.length === 0) {
                        clearInterval(_this.robotShowInstance);
                        _this.$message({
                            type: 'success',
                            message: '所有机器人已全部出现在左侧栏中，可以在左侧栏中滚动查看~',
                            duration: 2000
                        });
                    }
                } else {
                    clearInterval(_this.robotShowInstance);
                    _this.$message({
                        type: 'success',
                        message: '所有机器人已全部出现在左侧栏中，可以在左侧栏中滚动查看~',
                        duration: 2000
                    });
                }
            }, Number(interval_time) * 1000);
        },
        resetKefuModule() {
            this.$confirm('确定重置客服模块，重置之后，当前的答题记录将全部清空', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$http.axiosGet(this.$api.resetRobot, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: `${res.msg},1秒后将刷新页面`,
                            duration: 1000,
                            onClose: () => {
                                window.location.reload();
                            }
                        });
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err);
                })
            });
        }
    }
}